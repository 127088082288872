import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import "./ContactForm.scss";
import "./Adaptations.scss";

import { useTranslation } from "react-i18next";

import CrossThinImage from "../../../static/images/icons/cross-thin.svg";
import FullNameIcon from "../../../static/images/icons/user.svg";
import EmailIcon from "../../../static/images/icons/email.svg";

function ContactFormStatus({ t }) {
    return (
        <div className="contact-form-status">
            <div
                className="contact-form-status-title"
                style={{ fontFamily: t("font_salvager") }}
            >
                <span className="contact-form-status-title-text">
                    {t("contact_form_sent_your_way")}
                </span>
            </div>
            <div
                className="contact-form-status-desc"
                style={{ fontFamily: t("font_inter") }}
            >
                <span className="contact-form-status-desc-text">
                    {t("contact_form_would_be_appreciated")}
                </span>
            </div>
        </div>
    );
}

function ContactFormField({ label, name, id, type, icon, placeholder, t }) {
    return (
        <div className="contact-form-field">
            <div
                className="contact-form-field-label"
                style={{ fontFamily: t("font_inter") }}
            >
                <span className="contact-form-field-label-text">{label}</span>
            </div>
            <div className="contact-form-field-input-wrapper">
                <img
                    src={icon}
                    alt={`${label} icon`}
                    className="contact-form-field-icon"
                />
                <input
                    className="contact-form-field-input"
                    name={name}
                    id={id}
                    type={type}
                    required
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
}

function ContactFormPhoneField({
    label,
    placeholder,
    phoneInput,
    setPhoneInput,
    t,
}) {
    return (
        <div className="contact-form-field">
            <div
                className="contact-form-field-label"
                style={{ fontFamily: t("font_inter") }}
            >
                <span className="contact-form-field-label-text">{label}</span>
            </div>
            <div className="contact-form-field-input-wrapper">
                <PhoneInput
                    placeholder={placeholder}
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="SA"
                    value={phoneInput}
                    onChange={setPhoneInput}
                    withCountryCallingCode={true}
                />
            </div>
        </div>
    );
}

export default function ContactForm({
    title,
    form_id,
    closeFunc,
    electricTransformer,
    unitSize,
}) {
    const [t] = useTranslation();
    const [status, setStatus] = useState("");

    const [phoneInput, setPhoneInput] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);

        formData.append("Info", t(title));
        formData.append("Phone", phoneInput);

        try {
            const response = await fetch(`https://formspree.io/f/${form_id}`, {
                method: "POST",
                body: formData,
                headers: {
                    Accept: "application/json",
                },
            });
            if (response.status === 200) {
                setStatus("Form submitted successfully!");
                form.reset();
            } else {
                setStatus("Failed to submit the form. Please try again.");
            }
        } catch (error) {
            setStatus("Failed to submit the form. Please try again.");
        }
    };

    return (
        <div className="contact-form">
            <div className="contact-form-close" onClick={closeFunc}>
                <img src={CrossThinImage} alt="cross-close" />
            </div>
            {status ? (
                <ContactFormStatus t={t} />
            ) : (
                <>
                    <div
                        className="contact-form-title"
                        style={{ fontFamily: t("font_salvager") }}
                    >
                        <span className="contact-form-title-text">{title}</span>
                    </div>
                    {unitSize ? (
                        <div className="contact-form-unit-size">
                            <span className="contact-form-unit-size-label">
                                {t("unit_size")}:
                            </span>
                            &nbsp;
                            <span className="contact-form-unit-size-value">
                                {unitSize + t("square_meters")}
                            </span>
                        </div>
                    ) : null}
                    {electricTransformer ? (
                        <div>
                            <span className="contact-form-electric-transformer">
                                {t("land_info_electric_transformer")}
                            </span>
                        </div>
                    ) : null}
                    <form onSubmit={handleSubmit}>
                        <ContactFormField
                            label={t("contact_form_full_name")}
                            name="Name"
                            id="name"
                            type="text"
                            icon={FullNameIcon}
                            placeholder="Olivia Dou"
                            t={t}
                        />
                        <ContactFormField
                            label={t("contact_form_email")}
                            name="Email"
                            id="email"
                            type="email"
                            icon={EmailIcon}
                            placeholder="olivia@untitledui.com"
                            t={t}
                        />
                        <ContactFormPhoneField
                            label={t("contact_form_phone_number")}
                            placeholder="+9 (660) 000-0000"
                            phoneInput={phoneInput}
                            setPhoneInput={setPhoneInput}
                            t={t}
                        />
                        <button
                            className="contact-form-submit"
                            type="submit"
                            style={{ fontFamily: t("font_inter") }}
                        >
                            {t("contact_form_send")}
                        </button>
                    </form>
                </>
            )}
        </div>
    );
}
