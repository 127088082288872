import { createContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import "./Main.scss";

import { content_animation } from "./animations";
import { buttons } from "../../data/menu/buttons";

import Overlay from "../../layouts/overlay/Overlay";

import Loading from "../../components/loading/Loading";
import Menu from "../../components/main/menu/Menu";
import Modal from "../../components/modal/Modal";
import TopLogo from "../../components/main/menu/top-logo/TopLogo";

export const MainContext = createContext(null);

export default function Main() {
    const [content, setContent] = useState(null);
    const [contentChanged, setContentChanged] = useState(0);

    const [modalContent, setModalContent] = useState(null);

    const changeContent = (component, data) => {
        if (content?.component !== component) {
            setContent({
                component: component,
                data: data,
            });
            setContentChanged((contentChanged) => contentChanged + 1);
        }
    };

    const [pageClicked, setPageClicked] = useState(0);

    const handlePageClick = () => {
        setPageClicked((pageClicked) => pageClicked + 1);
    };

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);

    console.log('content: ', content)

    return (
        <div
            className="main"
            onMouseDown={handlePageClick}
            onClickCapture={handlePageClick}
        >
            <Overlay zIndex={5}>
                <Menu
                    buttons={buttons}
                    content={content}
                    changeContent={changeContent}
                    contentClicked={pageClicked}
                />
                <TopLogo isServiceButton={content?.data?.button !== "menu_buraydah"} setModalContent={setModalContent} />
                <AnimatePresence>
                    {modalContent && (
                        <Modal closeFunc={() => setModalContent(null)}>
                            {modalContent}
                        </Modal>
                    )}
                    {isLoading && <Loading isLoading={isLoading} />}
                </AnimatePresence>
            </Overlay>
            <MainContext.Provider
                value={[changeContent, setModalContent, pageClicked]}
            >
                <AnimatePresence mode="wait">
                    <motion.div
                        key={contentChanged}
                        {...content_animation}
                        className="main-content"
                    >
                        {content?.component}
                    </motion.div>
                </AnimatePresence>
            </MainContext.Provider>
        </div>
    );
}
