import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../Main";

import "./Zone5.scss";
import "./Adaptations.scss";
import { zone5_land_plots } from "./land_plots";
import { zone5_blocks } from "./blocks";

import Reel from "../../../../components/main/reel/Reel";
import MasterPlan from "../master-plan/MasterPlan";
import BackButton from "../../../../components/main/menu/back-button/BackButton";
import Switch from "../../../../components/switch/Switch";
import ServicesButton from "../../../../components/main/menu/services/ServicesButton";

import startFrame from "../../../../static/images/contents/zone-5/renders/1/7.jpg";

export default function Zone5() {
    const [t] = useTranslation();

    const [changeContent, setModalContent] = useContext(MainContext);
    const [switchState, setSwitchState] = useState(true);

    return (
        <div className="zone-5" style={{ width: "100%", height: "100%" }}>
            <div className="zone-5-switches">
                <Switch
                    switchChecked={switchState}
                    handleSwitchCheck={setSwitchState}
                    labelText={t("zone_5_switch_land_plot")}
                />
                <Switch
                    switchChecked={!switchState}
                    handleSwitchCheck={setSwitchState}
                    labelText={t("zone_5_switch_blocks")}
                />
            </div>
            <Reel
                folder="static/contents/zone-5/renders"
                amount={120}
                qualitySteps={1}
                defaultControlsPart={0.075}
                stops={switchState ? zone5_land_plots : zone5_blocks}
                overlay_elements={null} // Soon
                wrapper_animation_key={switchState}
                startFrame={startFrame}
            />

            <BackButton
                onClick={() =>
                    changeContent(<MasterPlan />, {
                        button: "menu_master_plan",
                        element: null,
                        head_title: null,
                    })
                }
            />
            <div className="services-button-wrapper">
                <ServicesButton setModalContent={setModalContent} />
            </div>
        </div>
    );
}
