import AlZumurudaIcon from "../../static/images/contents/buraydah/en/al_zumuruda.svg";
import QassimUniversityIcon from "../../static/images/contents/buraydah/en/qassim_university.svg";
import QassimBoulvardIcon from "../../static/images/contents/buraydah/en/qassim_boulevard.svg";
import InternationalAirportIcon from "../../static/images/contents/buraydah/en/international_airport.svg";
import KingAbdullahSportsCityIcon from "../../static/images/contents/buraydah/en/king_abdullah_sports_city.svg";

export const translationsEn = {
    menu_title: "Menu",
    menu_buraydah: "Buraydah",
    menu_master_plan: "3D MASTER PLAN",
    menu_zone_5: "3D Zone 5",
    menu_language: "العربية",
    menu_services: "Services",
    map_show_distances: "Show Distances",
    map_show_circles: "Show Circles",
    percentage_loading_title: "Loading...",
    land_info_title: "Land Plot",
    land_info_status_available: "Available",
    land_info_status_booked: "Booked",
    land_info_status_sold: "Sold",
    land_info_electric_transformer: "Electric transformer built up",
    block_info_title: "Block",
    back_button: "Back",
    contact_form_send: "Send",
    contact_form_full_name: "Full name",
    contact_form_email: "Email",
    contact_form_phone_number: "Phone number",
    contact_form_sent_your_way: "A request has been sent your way;",
    contact_form_would_be_appreciated:
        "Your prompt attention will be greatly appreciated",
    buraydah_al_zumuruda_icon: AlZumurudaIcon,
    buraydah_qassim_university_icon: QassimUniversityIcon,
    buraydah_qassim_boulevard_icon: QassimBoulvardIcon,
    buraydah_international_airport_icon: InternationalAirportIcon,
    buraydah_sports_city_icon: KingAbdullahSportsCityIcon,
    zone_5_switch_land_plot: "Land plots",
    zone_5_switch_blocks: "Blocks",
    font_inter: "Inter",
    font_salvager: "SALVAGER",
    electricity: "Electricity",
    water: "Water",
    roads: "Roads",
    stormwaterDrainage: "Stormwater Drainage",
    unit_size: "Unit Size",
    square_meters: "m²"
};
