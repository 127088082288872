import { useTranslation } from "react-i18next";

import "./LandInfo.scss";

export default function LandInfo({
    title,
    index,
    status,
    electricTransformer,
    unitSize,
}) {
    const [t] = useTranslation();

    return (
        <div className="land-info">
            <div className="land-info-title">
                <span
                    className="land-info-title-text"
                    style={{ fontFamily: t("font_salvager") }}
                >
                    {t(title)} {index}
                </span>
            </div>
            <div
                className={`land-info-status land-info-status-${status}`}
                style={{ fontFamily: t("font_salvager") }}
            >
                {unitSize ? <span className="land-info-unit-size">{t('unit_size')}: {unitSize + t('square_meters')}</span> : null}
                <span className="land-info-status-text">
                    {t(`land_info_status_${status}`)}
                </span>
                {electricTransformer ? (
                    <span className="land-info-electric-transformer">
                        {t("land_info_electric_transformer")}
                    </span>
                ) : null}
            </div>
        </div>
    );
}
