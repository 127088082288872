import { useJsApiLoader } from "@react-google-maps/api";
import { useContext } from "react";

import { MainContext } from "../../Main";
import Map from "../../../../components/map/Map";
import { markers } from "./markers";

import MasterPlan from "../master-plan/MasterPlan";
import TopLogo from "../../../../components/main/menu/top-logo/TopLogo";

export default function Buraydah() {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyDqb2XlJJbdHDsu8mDDz4oAAMdevM011bI",
    });

    const [changeContent] = useContext(MainContext);
    const openMasterPlan = () =>
        changeContent(<MasterPlan />, {
            button: "menu_master_plan",
            element: null,
            head_title: null,
        });

    return (
        <div className="buraydah" style={{ width: "100%", height: "100%" }}>
            <Map
                isLoaded={isLoaded}
                mapTypeId="roadmap"
                mapId="7d4e6b6ce1a313f1"
                markers={markers(openMasterPlan)}
                mapCenter={{ lat: 26.380906980874094, lng: 43.86028939122224 }}
            />
            <TopLogo />
        </div>
    );
}
