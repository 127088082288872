import Buraydah from "../../pages/main/contents/buraydah/Buraydah";
import MasterPlan from "../../pages/main/contents/master-plan/MasterPlan";
import Zone5 from "../../pages/main/contents/zone-5/Zone5";

export const buttons = [
    {
        title: "menu_buraydah",
        elements: [
            {
                content: <Buraydah />,
            },
        ],
    },
    {
        title: "menu_master_plan",
        elements: [
            {
                content: <MasterPlan />,
            },
        ],
    },
    {
        title: "menu_zone_5",
        elements: [
            {
                content: <Zone5 />,
            },
        ],
    },
];
