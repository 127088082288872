import AlZumurudaIcon from "../../static/images/contents/buraydah/ar/al_zumuruda.svg";
import QassimUniversityIcon from "../../static/images/contents/buraydah/ar/qassim_university.svg";
import QassimBoulvardIcon from "../../static/images/contents/buraydah/ar/qassim_boulevard.svg";
import InternationalAirportIcon from "../../static/images/contents/buraydah/ar/international_airport.svg";
import KingAbdullahSportsCityIcon from "../../static/images/contents/buraydah/ar/king_abdullah_sports_city.svg";

export const translationsAr = {
    menu_title: "قائمة",
    menu_buraydah: "بريدة",
    menu_master_plan: "المخطط",
    menu_zone_5: "منطقة 5",
    menu_language: "English",
    menu_services: "الخدمات",
    map_show_distances: "عرض المسافة",
    map_show_circles: "عرض الدوائر",
    percentage_loading_title: "تحميل",
    land_info_title: "قطعة أرض",
    land_info_status_available: "متاح",
    land_info_status_booked: "حجز",
    land_info_status_sold: "مُباع",
    land_info_electric_transformer: "محول كهربائي تم بناؤه",
    block_info_title: "بلوك",
    back_button: "الرجوع",
    contact_form_send: "إرسال",
    contact_form_full_name: "الاسم الكامل",
    contact_form_email: "بريد إلكتروني",
    contact_form_phone_number: "رقم الجوال",
    contact_form_sent_your_way: "لقد تم إرسال طلب في طريقك؛",
    contact_form_would_be_appreciated: "سيكون اهتمامك الفوري موضع تقدير كبير",
    buraydah_al_zumuruda_icon: AlZumurudaIcon,
    buraydah_qassim_university_icon: QassimUniversityIcon,
    buraydah_qassim_boulevard_icon: QassimBoulvardIcon,
    buraydah_international_airport_icon: InternationalAirportIcon,
    buraydah_sports_city_icon: KingAbdullahSportsCityIcon,
    zone_5_switch_land_plot: "قطعة أرض",
    zone_5_switch_blocks: "بلوك",
    font_inter: "PNU",
    font_salvager: "Etab",
    electricity: "الكهرباء",
    water: "الماء",
    roads: "الطرق",
    stormwaterDrainage: "تصريف مياه الأمطار",
    unit_size: " مساحة القطعة ",
    square_meters: "م٢"
};
