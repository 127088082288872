export const markers = (openMasterPlan) => [
    {
        id: "al_zumuruda",
        coords: { lat: 26.436224, lng: 43.844739 },
        icon: "buraydah_al_zumuruda_icon",
        anchor: { x: 46, y: 55 },
        onclick: () => openMasterPlan(),
    },
    {
        id: "qassim_university",
        coords: { lat: 26.348063007918846, lng: 43.767259405020575 },
        icon: "buraydah_qassim_university_icon",
        anchor: { x: 49.5, y: 43 },
        onclick: () => console.log("clicked"),
    },
    {
        id: "qassim_boulvard",
        coords: { lat: 26.39185711823994, lng: 43.91971539130326 },
        icon: "buraydah_qassim_boulevard_icon",
        anchor: { x: 49, y: 43 },
        onclick: () => console.log("clicked"),
    },
    {
        id: "international_airport",
        coords: { lat: 26.304184790034363, lng: 43.768580770991335 },
        icon: "buraydah_international_airport_icon",
        anchor: { x: 67.5, y: 43 },
        onclick: () => console.log("clicked"),
    },
    {
        id: "king_abdullah_sports_city",
        coords: { lat: 26.378517424205715, lng: 43.946965360178694 },
        icon: "buraydah_sports_city_icon",
        anchor: { x: 70, y: 55 },
        onclick: () => console.log("clicked"),
    },
];
