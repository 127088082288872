import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";
import SvgCustoms from "../../../../components/main/wrappers/svg-customs/SvgCustoms";

import VR from "../../../../components/main/vr/VR";
// import VideoVR from "../../../../components/main/vr/VideoVR";

import VR_C1 from "../../../../static/images/contents/master-plan/vr/C1_Low.jpg";
import VR_C2 from "../../../../static/images/contents/master-plan/vr/C2_Low.jpg";
import VR_C3 from "../../../../static/images/contents/master-plan/vr/C3_Low.jpg";

import { ReactComponent as Zone5_21 } from "../../../../static/images/contents/master-plan/polygons/21.svg";
import { ReactComponent as VR360_21_1 } from "../../../../static/images/contents/master-plan/icons/21/vr360_1.svg";
import { ReactComponent as VR360_21_2 } from "../../../../static/images/contents/master-plan/icons/21/vr360_2.svg";
import { ReactComponent as VR360_21_3 } from "../../../../static/images/contents/master-plan/icons/21/vr360_3.svg";

import { ReactComponent as Zone5_46 } from "../../../../static/images/contents/master-plan/polygons/46.svg";
import { ReactComponent as VR360_46_1 } from "../../../../static/images/contents/master-plan/icons/46/vr360_1.svg";
import { ReactComponent as VR360_46_2 } from "../../../../static/images/contents/master-plan/icons/46/vr360_2.svg";
import { ReactComponent as VR360_46_3 } from "../../../../static/images/contents/master-plan/icons/46/vr360_3.svg";

import { ReactComponent as Zone5_77 } from "../../../../static/images/contents/master-plan/polygons/77.svg";
import { ReactComponent as VR360_77_1 } from "../../../../static/images/contents/master-plan/icons/77/vr360_1.svg";
import { ReactComponent as VR360_77_2 } from "../../../../static/images/contents/master-plan/icons/77/vr360_2.svg";
import { ReactComponent as VR360_77_3 } from "../../../../static/images/contents/master-plan/icons/77/vr360_3.svg";

import { ReactComponent as Zone5_106 } from "../../../../static/images/contents/master-plan/polygons/106.svg";
import { ReactComponent as VR360_106_1 } from "../../../../static/images/contents/master-plan/icons/106/vr360_1.svg";
import { ReactComponent as VR360_106_2 } from "../../../../static/images/contents/master-plan/icons/106/vr360_2.svg";
import { ReactComponent as VR360_106_3 } from "../../../../static/images/contents/master-plan/icons/106/vr360_3.svg";

import { ReactComponent as Zone_5_Icon_21_EN } from "../../../../static/images/contents/master-plan/icons/21/en/3d_zone_5.svg";
import { ReactComponent as Zone_5_Icon_46_EN } from "../../../../static/images/contents/master-plan/icons/46/en/3d_zone_5.svg";
import { ReactComponent as Zone_5_Icon_77_EN } from "../../../../static/images/contents/master-plan/icons/77/en/3d_zone_5.svg";
import { ReactComponent as Zone_5_Icon_106_EN } from "../../../../static/images/contents/master-plan/icons/106/en/3d_zone_5.svg";

import { ReactComponent as Zone_5_Icon_21_AR } from "../../../../static/images/contents/master-plan/icons/21/ar/3d_zone_5.svg";
import { ReactComponent as Zone_5_Icon_46_AR } from "../../../../static/images/contents/master-plan/icons/46/ar/3d_zone_5.svg";
import { ReactComponent as Zone_5_Icon_77_AR } from "../../../../static/images/contents/master-plan/icons/77/ar/3d_zone_5.svg";
import { ReactComponent as Zone_5_Icon_106_AR } from "../../../../static/images/contents/master-plan/icons/106/ar/3d_zone_5.svg";
import VideoVR from "../../../../components/main/vr/VideoVR";

export const masterPlanStops = (openZone5, setModalContent, language) => [
    {
        index: 21,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: Zone5_21,
                            className: "zone-5-area",
                            infobox: null,
                            onClick: openZone5,
                            isInfoboxOnMobile: false,
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: VR360_21_1,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C2} />
                                ),
                        },
                        {
                            Element: VR360_21_2,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    // <VR id="walking-area" panorama={VR_C1} />
                                    <VideoVR />
                                ),
                        },
                        {
                            Element: VR360_21_3,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C3} />
                                ),
                        },
                        {
                            Element:
                                language === "en"
                                    ? Zone_5_Icon_21_EN
                                    : Zone_5_Icon_21_AR,
                            className: "zone-5-icon",
                            onClick: () => console.log("clicked"),
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 46,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: Zone5_46,
                            className: "zone-5-area",
                            infobox: null,
                            onClick: openZone5,
                            isInfoboxOnMobile: false,
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: VR360_46_1,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C2} />
                                ),
                        },
                        {
                            Element: VR360_46_2,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    // <VR id="walking-area" panorama={VR_C1} />
                                    <VideoVR />
                                ),
                        },
                        {
                            Element: VR360_46_3,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C3} />
                                ),
                        },
                        {
                            Element:
                                language === "en"
                                    ? Zone_5_Icon_46_EN
                                    : Zone_5_Icon_46_AR,
                            className: "zone-5-icon",
                            onClick: () => console.log("clicked"),
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 77,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: Zone5_77,
                            className: "zone-5-area",
                            infobox: null,
                            onClick: openZone5,
                            isInfoboxOnMobile: false,
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: VR360_77_1,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C2} />
                                ),
                        },
                        {
                            Element: VR360_77_2,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    // <VR id="walking-area" panorama={VR_C1} />
                                    <VideoVR />
                                ),
                        },
                        {
                            Element: VR360_77_3,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C3} />
                                ),
                        },
                        {
                            Element:
                                language === "en"
                                    ? Zone_5_Icon_77_EN
                                    : Zone_5_Icon_77_AR,
                            className: "zone-5-icon",
                            onClick: () => console.log("clicked"),
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 106,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: Zone5_106,
                            className: "zone-5-area",
                            infobox: null,
                            onClick: openZone5,
                            isInfoboxOnMobile: false,
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: VR360_106_1,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C2} />
                                ),
                        },
                        {
                            Element: VR360_106_2,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    // <VR id="walking-area" panorama={VR_C1} />
                                    <VideoVR />
                                ),
                        },
                        {
                            Element: VR360_106_3,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C3} />
                                ),
                        },
                        {
                            Element:
                                language === "en"
                                    ? Zone_5_Icon_106_EN
                                    : Zone_5_Icon_106_AR,
                            className: "zone-5-icon",
                            onClick: () => console.log("clicked"),
                        },
                    ]}
                />
            </>
        ),
    },
];
