import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {
    GoogleMap,
    MarkerF,
    PolygonF,
    InfoWindowF,
    DirectionsRenderer,
    MarkerClustererF,
    CircleF,
    PolylineF,
} from "@react-google-maps/api";

import "./Map.scss";
import "./Adaptations.scss";
import Switch from "../switch/Switch";
import { useTranslation } from "react-i18next";

import Mark5KM from "../../static/images/mark-5km.svg";
import Mark10KM from "../../static/images/mark-10km.svg";
import Mark15KM from "../../static/images/mark-15km.svg";
import Mark20KM from "../../static/images/mark-20km.svg";

// import { infrastrMarkers, markers, polygons, polylines } from "../../pages/main/contents/buraydah/markers";

function MapMarker({
    position,
    options,
    icon,
    activeIcon,

    onMouseOver,
    onMouseOut,
    onClick,

    routeMarkers,
}) {
    const markerRef = useRef(null);

    // const handleMarkerLoad = (marker) => {
    //     markerRef.current = marker;

    //     markerRef.current.setOpacity(0);
    // };

    // const handleMarkerClick = () => {
    //     // nothing
    // };

    // const steps = [1, 0.9, 0.8, 0.7, 0.6, 0.5];
    // const stepsReversed = [...steps].reverse();
    // let stepTime = 20;

    // const changeOpacity = (stepsInput) => {
    //     if (
    //         !(
    //             markerRef.current.getOpacity() ==
    //             stepsInput[stepsInput.length - 1]
    //         )
    //     ) {
    //         let currentTime = stepTime;
    //         for (let step of stepsInput) {
    //             setTimeout(() => {
    //                 markerRef.current.setOpacity(step);
    //             }, currentTime);
    //             currentTime = currentTime + stepTime;
    //         }
    //     }
    // };

    // useEffect(() => {
    //     if (routeMarkers.length !== 0) {
    //         if (routeMarkers.includes(position)) {
    //             changeOpacity(stepsReversed);
    //         } else {
    //             changeOpacity(steps);
    //         }
    //     } else {
    //         changeOpacity(stepsReversed);
    //     }
    // }, [routeMarkers]);

    // const [isCursorHover, setIsCursorHover] = useState(false);
    // useEffect(() => {
    //     if (activeIcon) {
    //         if (isCursorHover) {
    //             markerRef.current.setOptions({
    //                 icon: icon,
    //             });
    //         } else {
    //             markerRef.current.setOptions({
    //                 icon: activeIcon,
    //             });
    //         }
    //     }
    // }, [isCursorHover]);

    return (
        <MarkerF
            position={position}
            options={{
                ...options,
            }}
            icon={activeIcon ? activeIcon : icon}
            // onMouseOver={() => {
            //     onMouseOver();
            //     setIsCursorHover(true);
            // }}
            // onMouseOut={() => {
            //     onMouseOut();
            //     setIsCursorHover(false);
            // }}
            onClick={() => {
                onClick();
                // handleMarkerClick();
            }}
            // onLoad={handleMarkerLoad}
        >
            {/* {
                activeMarker === id && (
                    <InfoWindowF options={{disableAutoPan: true}}  >
                        <div>{1}</div>
                    </InfoWindowF>
                )
            } */}
        </MarkerF>
    );
}

export default function Map({
    isLoaded,
    mapTypeId,
    mapId,
    markers,
    mapCenter,
}) {
    const [t] = useTranslation();

    const center = useMemo(() => mapCenter, []);

    const [routeMarkers, setRouteMarkers] = useState([]);
    const [circlesCenter, setCirclesCenter] = useState(null);

    const mapRef = useRef();

    const [directions, setDirections] = useState();
    const onLoad = useCallback((map) => {
        mapRef.current = map;

        mapRef.google = window.google;
        mapRef.directionsService = new mapRef.google.maps.DirectionsService();
        mapRef.directionsRenderer = new mapRef.google.maps.DirectionsRenderer();
    }, []);

    const fetchDirections = (coords) => {
        mapRef.directionsService.route(
            {
                origin: { lat: 26.436224, lng: 43.844739 },
                destination: coords,
                travelMode: mapRef.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === "OK" && result) {
                    const path = result.routes[0].overview_path;
                    const midPointIndex = Math.floor(path.length / 2);
                    mapRef.midPoint = path[midPointIndex];

                    // mapRef.current.panTo(mapRef.midPoint);

                    setDirections(result);
                    console.log(directions);
                }
            }
        );
    };

    const [directionSwitchChecked, setDirectionSwitchChecked] = useState(false);
    const [radiusSwitchChecked, setRadiusSwitchChecked] = useState(false);

    const handleMarkerClick = (coords) => {
        if (directionSwitchChecked) {
            setDirections(null);
            fetchDirections(coords);
        } else if (radiusSwitchChecked) {
            setCirclesCenter(coords);
            // mapRef.current.panTo(coords);
        }
    };

    // Calculations
    const earthRadius = 6378137;
    const metersToLatLng = (
        distanceInMetersLat,
        distanceInMetersLng,
        latitude,
        longitude
    ) => {
        const distanceInRadiansLat = distanceInMetersLat / earthRadius;
        const distanceInRadiansLng = distanceInMetersLng / earthRadius;

        const latitudeInRadians = (latitude * Math.PI) / 180;
        const longitudeInRadians = (longitude * Math.PI) / 180;

        const newLatitudeInRadians = latitudeInRadians + distanceInRadiansLat;
        const newLongitudeInRadians = longitudeInRadians + distanceInRadiansLng;

        const newLatitude = (newLatitudeInRadians * 180) / Math.PI;
        const newLongitude = (newLongitudeInRadians * 180) / Math.PI;

        return { lat: newLatitude, lng: newLongitude };
    };

    return (
        <div className="map">
            <div className="map-switches">
                <Switch
                    switchChecked={directionSwitchChecked}
                    handleSwitchCheck={(value) => {
                        setDirectionSwitchChecked(value);

                        setRadiusSwitchChecked(false);
                        setCirclesCenter(null);
                    }}
                    labelText={t("map_show_distances")}
                />
                <Switch
                    switchChecked={radiusSwitchChecked}
                    handleSwitchCheck={(value) => {
                        setRadiusSwitchChecked((state) => {
                            if (state) {
                                setCirclesCenter(null);
                            }

                            return value(state);
                        });

                        setDirectionSwitchChecked(false);
                        setDirections(null);
                    }}
                    labelText={t("map_show_circles")}
                />
            </div>
            {isLoaded ? (
                <GoogleMap
                    zoom={12}
                    center={center}
                    mapContainerClassName="map-container"
                    heading={0}
                    onLoad={onLoad}
                    onClick={() => {
                        setDirections(undefined);
                        setCirclesCenter(null);
                        setRouteMarkers([]);
                    }}
                    onDrag={() => {}}
                    onZoomChanged={() => {}}
                    options={{
                        tilt: 50,
                        maxZoom: 13,
                        mapId: mapId,
                        disableDefaultUI: true,
                        restriction: {
                            latLngBounds: {
                                north: 26.912150736799223,
                                south: 26.08571565339379,
                                west: 43.17861429571154,
                                east: 44.80340021219638,
                            },
                            strictBounds: false,
                        },
                        mapTypeId: mapTypeId,
                    }}
                >
                    {/* Infrastructure Markers Mapping */}
                    {/* {infrastrMarkers.map(
                        ({ id, allCoords, icon, label }) =>
                            checkboxFiltersRadio.includes(id) &&
                            allCoords.map((coords) => (
                                <MarkerF
                                    position={{
                                        lat: coords[0],
                                        lng: coords[1],
                                    }}
                                    icon={icon}
                                    onClick={() =>
                                        handleMarkerClick(
                                            1,
                                            { lat: coords[0], lng: coords[1] },
                                            null,
                                            "infrastr"
                                        )
                                    }
                                    routeMarkers={routeMarkers}
                                    options={{ clickable: true }}
                                    onMouseOver={() => setActiveMarker(coords)}
                                    onMouseOut={() => setActiveMarker(null)}
                                >
                                    {(activeMarker === coords ||
                                        activeMarker2 === coords) && (
                                        <InfoWindowF
                                            options={{ disableAutoPan: true }}
                                        >
                                            <div>
                                                <span>
                                                    {coords.name
                                                        ? coords.name
                                                        : id}
                                                </span>
                                            </div>
                                        </InfoWindowF>
                                    )}
                                </MarkerF>
                            ))
                    )} */}

                    {/* Markers Mapping */}
                    {markers.map(({ id, coords, icon, anchor, onclick }) => (
                        <MapMarker
                            position={coords}
                            options={{
                                zIndex: 10,
                            }}
                            icon={{
                                url: t(icon),
                                anchor: anchor
                                    ? new window.google.maps.Point(
                                          anchor.x,
                                          anchor.y
                                      )
                                    : null,
                            }}
                            // onMouseOver={() => setActiveMarker(id)}
                            // onMouseOut={() => setActiveMarker(null)}
                            // onClick={() => handleMarkerClick(coords)}
                            // onClick={onclick}
                            onClick={() => {
                                if (id === "al_zumuruda") {
                                    onclick();
                                    return;
                                }
                                handleMarkerClick(coords);
                            }}
                            routeMarkers={routeMarkers}
                        />
                    ))}

                    {/* Polygons Mapping */}
                    {/* {markers.map(
                        ({
                            id,
                            checkIsInGroup,
                            groupName,
                            coords,
                            polygonCoords,
                            icon,
                            activeIcon,
                            clickable,
                            content,
                        }) =>
                            checkIsInGroup &&
                            masterCommunitiesChecked &&
                            groupName
                                .map((name) =>
                                    checkboxFiltersRadio.includes(name)
                                )
                                .every((item) => item === true) &&
                            polygonCoords &&
                            polygonCoords.map((paths) => (
                                <PolygonF
                                    paths={paths}
                                    options={polygonOptions(clickable.polygon)}
                                    onMouseOver={() => setActiveMarker(id)}
                                    onMouseOut={() => setActiveMarker(null)}
                                    onClick={() =>
                                        handleMarkerClick(id, coords, content)
                                    }
                                />
                            ))
                    )} */}

                    {/* Polyline Mapping */}
                    {/* {checkboxFiltersRadio.includes("transport") &&
                        polylines.map(({ id, path, stops, icon, color }) => (
                            <>
                                <PolylineF
                                    path={path}
                                    options={{
                                        strokeColor: color,
                                        strokeOpacity: 1,
                                        strokeWeight: 4,
                                        clickable: false,
                                    }}
                                />
                                {stops.map(({ name, coords }) => (
                                    <MarkerF
                                        position={coords}
                                        icon={icon}
                                        options={{ clickable: true, zIndex: 1 }}
                                        onMouseOver={() =>
                                            setActiveMarker(coords)
                                        }
                                        onMouseOut={() => setActiveMarker(null)}
                                        onClick={() => setActiveMarker2(coords)}
                                        routeMarkers={routeMarkers}
                                    >
                                        {(activeMarker === coords ||
                                            activeMarker2 === coords) && (
                                            <InfoWindowF
                                                options={{
                                                    disableAutoPan: true,
                                                }}
                                            >
                                                <div>
                                                    <span>{name}</span>
                                                </div>
                                            </InfoWindowF>
                                        )}
                                    </MarkerF>
                                ))}
                            </>
                        ))} */}

                    {/* Drawing Directions */}
                    {directions && directionSwitchChecked && (
                        <>
                            <DirectionsRenderer
                                directions={directions}
                                options={{
                                    suppressMarkers: true,
                                    polylineOptions: {
                                        zIndex: 500,
                                        strokeColor: "#FFFFFF",
                                        strokeWeight: 4,
                                    },
                                    disableAutoPan: true,
                                    preserveViewport: true,
                                }}
                            />

                            <InfoWindowF position={mapRef.midPoint}>
                                <div
                                    className="directions-infowindow"
                                    style={{ fontFamily: t("font_inter") }}
                                >
                                    <span>
                                        {directions.routes[0].legs[0].distance
                                            .text +
                                            " | " +
                                            directions.routes[0].legs[0]
                                                .duration.text +
                                            " "}
                                    </span>
                                </div>
                            </InfoWindowF>
                        </>
                    )}

                    {/* Drawing Circles */}
                    {circlesCenter && radiusSwitchChecked && (
                        <>
                            <CircleF
                                center={circlesCenter}
                                radius={5000}
                                options={circleOptions}
                            />
                            <CircleF
                                center={circlesCenter}
                                radius={10000}
                                options={circleOptions}
                            />
                            <CircleF
                                center={circlesCenter}
                                radius={15000}
                                options={circleOptions}
                            />
                            <CircleF
                                center={circlesCenter}
                                radius={20000}
                                options={circleOptions}
                            />

                            <MarkerF
                                icon={Mark5KM}
                                position={metersToLatLng(
                                    3750,
                                    -3750,
                                    circlesCenter["lat"],
                                    circlesCenter["lng"]
                                )}
                                options={{ clickable: false }}
                            />
                            <MarkerF
                                icon={Mark10KM}
                                position={metersToLatLng(
                                    7500,
                                    -7500,
                                    circlesCenter["lat"],
                                    circlesCenter["lng"]
                                )}
                                options={{ clickable: false }}
                            />
                            <MarkerF
                                icon={Mark15KM}
                                position={metersToLatLng(
                                    11250,
                                    -11250,
                                    circlesCenter["lat"],
                                    circlesCenter["lng"]
                                )}
                                options={{ clickable: false }}
                            />
                            <MarkerF
                                icon={Mark20KM}
                                position={metersToLatLng(
                                    15000,
                                    -15000,
                                    circlesCenter["lat"],
                                    circlesCenter["lng"]
                                )}
                                options={{ clickable: false }}
                            />

                            <MarkerF
                                icon={Mark10KM}
                                position={metersToLatLng(
                                    6750,
                                    6750,
                                    circlesCenter["lat"],
                                    circlesCenter["lng"]
                                )}
                                options={{ clickable: false }}
                            />
                            <MarkerF
                                icon={Mark20KM}
                                position={metersToLatLng(
                                    14000,
                                    14000,
                                    circlesCenter["lat"],
                                    circlesCenter["lng"]
                                )}
                                options={{ clickable: false }}
                            />
                        </>
                    )}
                </GoogleMap>
            ) : (
                <h1>Loading...</h1>
            )}
        </div>
    );
}

const polygonOptions = (clickable) => ({
    fillColor: "rgba(250, 133, 79, 0.5)",
    fillOpacity: 1,
    strokeColor: "rgba(250, 133, 79, 0.9)",
    strokeOpacity: 0.85,
    strokeWeight: 2,
    clickable: clickable,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
});

const circleOptions = {
    strokeOpacity: 0.5,
    strokeWeight: 1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    fillOpacity: 0.075,
    strokeColor: "#FFFFFF",
    fillColor: "#FFFFFF",
};
