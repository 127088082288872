import { useContext } from "react";
import { useTranslation } from "react-i18next";

import "./SvgAreas.scss";

import { WithInfobox } from "../../../infobox/Infobox";
import LandInfo from "../../../infobox/land-info/LandInfo";
import { MainContext } from "../../../../pages/main/Main";

import ContactForm from "../../contact-form/ContactForm";

import landsJSON from "../../../../static/lands.json";

function SvgArea({
    Polygon,
    className,
    infobox,
    isClickableOnMobileInfobox = true,
    onClick,
}) {
    const [t] = useTranslation();
    const [changeContent, setModalContent] = useContext(MainContext);

    let title;
    let infoboxInner;

    if (infobox?.type === "land_info" || infobox?.type === "block_info") {
        title = infobox.type + "_title";
        if (!infobox.hidden) {
            infoboxInner = (
                <LandInfo
                    title={title}
                    index={infobox.index}
                    status={infobox.status}
                    electricTransformer={infobox.electricTransformer}
                    unitSize={
                        infobox.type === "land_info"
                            ? landsJSON.find(
                                  (land) => land.Unit === infobox.index
                              )?.UnitSize
                            : null
                    }
                />
            );
        }

        className = "zone-5-area";

        switch (infobox.status) {
            case "booked":
                className += " area-booked";
                break;
            case "sold":
                className += " area-sold";
                break;
            default:
                className += " area-available";
                break;
        }
    }

    if (onClick?.action) {
        switch (onClick.action) {
            case "open_contact_form":
                onClick = () => {
                    setModalContent(
                        <ContactForm
                            title={`${t(title)} ${infobox.index}`}
                            form_id="xyzgkeey"
                            electricTransformer={infobox.electricTransformer}
                            unitSize={
                                infobox.type === "land_info"
                                    ? landsJSON.find(
                                          (land) => land.Unit === infobox.index
                                      )?.UnitSize
                                    : null
                            }
                        />
                    );
                };
                break;
            default:
                break;
        }
    }

    return infobox ? (
        <WithInfobox
            inner={infoboxInner}
            isClickableOnMobileInfobox={isClickableOnMobileInfobox}
            onClick={onClick}
        >
            <Polygon
                className={`svg-area ${className ? className : ""}`}
                onMouseDown={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
            />
        </WithInfobox>
    ) : (
        <Polygon
            className={`svg-area ${className ? className : ""}`}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            onClick={onClick}
        />
    );
}

export default function SvgAreas({ areas }) {
    return (
        <div className="svg-areas">
            <svg
                viewBox="0 0 1920 1080"
                version="1.0"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
            >
                {areas.map((area, i) => (
                    <SvgArea key={i} {...area} />
                ))}
            </svg>
        </div>
    );
}
