import "./TopLogo.scss";
import "./Adaptations.scss";

import TopLogoImage from "../../../../static/images/text_logo.svg";
import ServicesButton from "../services/ServicesButton";

export default function TopLogo({ isServiceButton = false, setModalContent }) {
    return (
        <div className="top-logo">
            <img
                className="top-logo-image"
                src={TopLogoImage}
                alt="alaqtar-logo"
            />
            {isServiceButton ? <div className="service-button-wrapper">
                <ServicesButton setModalContent={setModalContent} />
            </div> : null}
        </div>
    );
}
